import React from "react";
import '../styles/long1.css';
import '../styles/gogogo.css';
import SEO from "../components/SEO";


const IndexPage = () => (
    <div class="grid-container">
    <div className="header">
        <div className="header1"> 
            <div className="logo-div go-logo-div">
                <a aria-current="page" className="logo" href="/"><div className="driverprofile-logo"></div></a>
            </div>
        </div>
        <a href="https://www.texpresslanes.com/" target="_blank"><div className="header2">What are TEXpress Lanes</div></a>
        <a href="https://www.texpresslanes.com/maps/texpress-lanes-map" target="_blank"><div className="header3">Where are TEXpress Lanes</div></a>
        <a href="https://www.texpresslanes.com/navigating-texpress-lanes/how-to-use" target="_blank"><div className="header4">How to Use TEXpress Lanes</div></a>
   </div>
    <div class="left_space"></div>
    <div className="short1-main">
        <div className="driverprofile-main1 gogogo-bg">
            <div className="new-santa-img"></div>
            <div className="header-text-sec">
                <h2 className="gopage-subheader">The sweepstakes entry period has ended.</h2>
                <h1 className="gopage-header">Thanks to everyone who entered in our Go Go Go Giveaway; the sweepstakes is now closed. Our team is verifying all submitted entries and the 5 winners will be contacted directly the week of January 18, 2021.<br/>Best of luck!</h1>
            </div>
        </div>
        <div className="gogogo-main2">
            <div className="go-section2">
                <div className="go-section2-left">
                    <div className="redline-img"></div>
                    <p className="go-section2-left-text">What Are The LBJ/NTE/NTE 35W TEXpress Lanes?</p>
                </div>
                <div className="go-section2-right">
                    <p className="go-section2-right-text">LBJ/NTE/NTE 35W TEXpress Lanes are unique toll lanes built within existing highways to accommodate more traffic volume. Unlike traditional toll roads, the price changes based on real-time traffic conditions in order to reduce congestion and keep everyone moving at least 50 MPH.</p>
                </div>
            </div>
            <div className="go-section2">
                <div className="go-section2-left">
                    <div className="redline-img"></div>
                    <p className="go-section2-left-text">How Can I Use the LBJ/NTE/NTE 35W TEXpress Lanes?</p>
                </div>
                <div className="go-section2-right">
                    <p className="go-section2-right-text">Got a TollTag, TxTag or EZ TAG? You're ready to use the LBJ/NTE/NTE 35W TEXpress Lanes and enter the sweepstakes promotion!</p>
                </div>
            </div>
        </div>
        <div className="gogogo-main3">
            <div className="register-box go-register-box">
                <p className="register-box-text">Need help finding your toll transponder number?</p>
                    <p className="register-box-text">Here are two ways you can locate your toll transponder number:</p>
                    <div className="register-bot-img"></div>
                <p className="register-box-text">Refer to the images above to find the information printed on your toll transponder </p>
                <p className="register-box-text">on your vehicle's windshield. The toll transponder number is printed on the side facing the driver in the vehicle.</p>
                <p className="register-box-text">You can also find your toll transponder number by logging into your respective toll transponder account. </p>
                <div className="register-box-link"><a href="https://www.txtag.org/txtagstorefront/en/" target="_blank">Visit My TxTag Account</a>   <span className="mobile-line">|</span>    <a href="https://www.hctra.org/Login" target="_blank">Visit My EZ TAG Account</a>    <span className="mobile-line">|</span>    <a href="https://csc.ntta.org/olcsc/" target="_blank">Visit My TollTag Account</a> </div>
            </div>
        </div>
        <div className="gogogo-main4">
            <div className="go-main4-padding">
                <p className="gogogo-main4-header">Where Can I Use The LBJ/NTE/NTE 35W TEXpress Lanes?</p>
                <p className="gogogo-main4-small-text">Sweepstakes promotion valid only on our lanes highlighted blue on the map below:</p>
                <p className="gogogo-main4-small-text">LBJ Express (I-635/LBJ Freeway and I-35E flyover ramps) &amp; North Tarrant Express (NE Loop 820, SH 121/183 Airport Freeway and I-35W)</p>
                <div className="map-img"></div>
                <a className="go-linkStyle" href="#"><p className="go-button">Enter Now</p></a>
            </div>
        </div>
    </div>
    <div class="right_space"></div>
    <div class="footer">
            <div class="register-footer1"><a href="https://www.facebook.com/TEXpressLanes" target="_blank"><img class="fb-icon"
  src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"/></a> | <a href="https://twitter.com/texpresslanes" target="_blank"><img class="twitter-icon"
  src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"/></a> |  <a href="https://www.texpresslanes.com/privacy-policy" target="_blank">Privacy Policy</a> │ ©2020 LBJ/NTE/NTE 35W TEXpress Lanes | All Rights Reserved</div>
        </div>
</div>
);

export default IndexPage;
